// Vendors
import jquery from 'jquery/dist/jquery.js';

// Modules
import burgerMenu from './modules/navigation-menu.js';
import clock from './modules/hours-clock.js';

// Styles
import styles from '../css/common.scss';

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
